<template>
    <div class="modal-status-form-auth">
        <div class="modal-status-form-auth__title"
            v-text="'Авторизация'"
        />
        <div class="modal-status-form-auth__button"
            v-if="getAuthUrlResult"
            >
            <ui-button
                v-bind:href="getAuthUrlResult.url"
                >
                кабинет акционера
            </ui-button>
        </div>
        <div class="modal-status-form-auth__description _error"
            v-if="getAuthUrlError"
            v-text="'Ошибка запроса ссылки авторизации кабинета акционера'"
        />
        <div class="modal-status-form-auth__button">
            <br />
            <br />
            <ui-button v-on:click="authorize">
                [DEBUG] Skip Auth
            </ui-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'modal-status-form-auth',
    computed: {
        ...mapState('status', [
            'getAuthUrlError',
            'getAuthUrlResult',
        ]),
    },
    methods: {
        authorize() {
            this.$store.state.status.isAuthorized = true;
        },
    },
    beforeMount() {
        this.$store.dispatch('status/getAuthUrl');
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-status-form-auth {
    &__title {
        .typography-heading();

        margin-bottom: 24px;

        text-align: center;
    }
    &__description {
        .typography-body();

        margin-bottom: 24px;

        text-align: center;
        &._error {
            color: @color-accent-negative;
        }
    }
    &__button {
        width: fit-content;
        margin: 0 auto;
    }
}
</style>
