<template>
    <div class="page-admin-estates-item-requests-item"
        v-bind:class="{ _canceled: item.status === 'canceled' }"
        >
        <div class="page-admin-estates-item-requests-item__content">
            <div class="page-admin-estates-item-requests-item__content-date"
                v-text="item.created"
            />
            <div class="page-admin-estates-item-requests-item__content-name">
                <a v-bind:href="`/admin/user/user/${item.applicant.id}/change/`" target="_blank">
                    <template v-if="item.applicant.legalType === 'J'">
                        {{ item.applicant.organizationName }}
                    </template>
                    <template v-else>
                        {{ item.applicant.fullName }}
                    </template>
                </a>
                <template v-if="item.isNamesake">
                    <br />
                    <span class="page-admin-estates-item-requests-item__content-name-account"
                        v-text="item.account"
                    />
                </template>
            </div>
            <div class="page-admin-estates-item-requests-item__content-phone"
                v-text="phoneFormatted"
            />
            <div class="page-admin-estates-item-requests-item__content-badges">
                <div class="page-admin-estates-item-requests-item__content-badges-item">
                    <ui-label
                        v-bind:theme="legalTypeBadge.theme"
                        v-bind:icon-left="legalTypeBadge.icon"
                        is-uppercase
                        >
                        {{ legalTypeBadge.title }}
                    </ui-label>
                </div>
                <div class="page-admin-estates-item-requests-item__content-badges-item"
                    v-if="item.isVip"
                    >
                    <ui-label
                        is-uppercase
                        theme="gradient"
                        >
                        Vip
                    </ui-label>
                </div>
            </div>
            <div class="page-admin-estates-item-requests-item__content-amount"
                v-text="item.amountFormatted"
            />
            <div class="page-admin-estates-item-requests-item__content-status"
                v-bind:class="{
                    _signed: item.status === 'signed',
                    _canceled: item.status === 'canceled' || item.status === 'merged' || item.status === 'refunded',
                    _waiting: item.status === 'wait_sign',
                }"
                v-text="status"
            />
            <div class="page-admin-estates-item-requests-item__content-actions">
                <div class="page-admin-estates-item-requests-item__content-actions-item"
                    v-if="isEditAvailable"
                    v-on:click="edit"
                    >
                    <icon name="edit" />
                </div>
                <div class="page-admin-estates-item-requests-item__content-actions-item"
                    v-if="isDownloadAvailable"
                    v-on:click="download"
                    >
                    <icon name="download" />
                </div>
            </div>
        </div>
        <div class="page-admin-estates-item-requests-item__error"
            v-if="error"
            v-text="error"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import utils from '~/utils';

export default {
    name: 'page-admin-estates-item-requests-item',
    props: {
        item: {
            type: Object,
        },
        estateId: {
            type: Number,
        },
    },
    data: () => ({
        // isCancelRequested: false,
        error: null,
    }),
    computed: {
        ...mapState('admin/estates', [ 'getEstateInfoResult' ]),
        ...mapState('admin/loans', [ 'getLoanResult' ]),
        legalTypeBadge() {
            if (this.item.applicant.legalType === 'F') {
                return { theme: 'fade', title: 'ФЛ', icon: 'legal-entity-f' };
            } else if (this.item.applicant.legalType === 'I') {
                return { theme: 'inversed', title: 'ИП', icon: 'legal-entity-i' };
            } else if (this.item.applicant.legalType === 'J') {
                return { theme: 'primary', title: 'ЮЛ', icon: 'legal-entity-j' };
            }
            return { theme: 'negative', title: 'Неизвестный тип аккаунта' };
        },
        phoneFormatted() {
            return this.item.applicant?.phone ? utils.formats.formatPhone(this.item.applicant.phone) : 'Нет номера';
        },
        isEditAvailable() {
            // if (this.item.status === 'signed' || this.getLoanResult) {
            if (this.item.status !== 'new') {
                return false;
            }
            const estateValidStatuses = [
                'new',
                'collected',
                'wait_sign',
            ];
            return estateValidStatuses.indexOf(this.getEstateInfoResult.status) > -1;
        },
        isDownloadAvailable() {
            return this.item.investmentAgreement ||
                this.item.investmentProposal ||
                this.item.transferOrder;
        },
        status() {
            if (this.item.status === 'waiting') {
                return '';
            }
            if (this.item.status === 'wait_sign') {
                return 'Ожидает подписи';
            }
            if (this.item.status === 'canceled') {
                return 'Отменена';
            }
            if (this.item.status === 'signed') {
                return 'Подписана';
            }
            if (this.item.status === 'merged') {
                return 'Объединена';
            }
            if (this.item.status === 'completed') {
                return 'Выполнена';
            }
            if (this.item.status === 'refunded') {
                return 'Возврат';
            }
            return '';
        },
    },
    methods: {
        edit() {
            if (!this.isEditAvailable) {
                return;
            }
            this.$store.commit('modals/push', {
                name: 'edit-investment-request',
                props: {
                    request: this.item,
                    estateId: this.estateId,
                    onComplete: () => {
                        this.$emit('request-reload');
                    },
                },
            });
        },
        download() {
            if (!this.isDownloadAvailable) {
                return;
            }
            this.$store.commit('modals/push', {
                name: 'download',
                props: {
                    mode: 'single-investment-request',
                    dataToDownload: this.item,
                },
            });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-admin-estates-item-requests-item {
    .typography-body();

    position: relative;

    padding: 8px 16px;
    border-radius: @border-radius-lg;

    background-color: @color-gray-lightest;
    &._canceled {
        background-color: @color-gray-light;
    }
    &__content {
        display: flex;
        align-items: center;
        gap: 8px;
        &-date {
            flex: 0 0 70px;

            color: @color-gray-main;
        }
        &-name {
            flex: 0 1 250px;
            &-account {
                .typography-caption();

                color: @color-gray-main;
            }
        }
        &-phone {
            flex: 0 0 125px;
        }
        &-badges {
            flex: 0 0 120px;
            display: flex;
            align-items: center;
            gap: 8px;
            &-item {
                flex: 0 0 auto;
            }
        }
        &-amount {
            flex: 0 0 100px;
        }
        &-status {
            flex: 0 0 75px;
            &._signed {
                .typography-heading();

                color: @color-positive;
            }
            &._waiting,
            &._canceled {
                .typography-body();

                color: @color-gray-main;
            }
        }
        &-actions {
            flex: 0 0 auto;
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: flex-end;
            margin-right: 0;
            margin-left: auto;
            &-item {
                flex: 0 0 auto;
                display: block;
                padding: 4px;

                font-size: 1.8rem;
                color: @color-gray-main;
                text-decoration: none;

                cursor: pointer;
                transition: color @duration-fast @easing-default;
                &:hover {
                    color: @color-gray-darkest;
                }
            }
        }
    }
    &__error {
        max-width: 320px;
        margin: 8px 0 0 auto;

        color: @color-accent-negative;
        text-align: right;
    }
    @media @media-sm-down {
        &__content {
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
            &-date {
                flex: 0 0 auto;
            }
            &-name {
                flex: 0 0 auto;
            }
            &-phone {
                flex: 0 0 auto;
            }
            &-badges {
                flex: 0 0 auto;
            }
            &-amount {
                flex: 0 0 auto;
            }
            &-status {
                flex: 0 0 auto;
            }
            &-actions {
                flex: 0 0 auto;
                margin: 0 auto 0 0;
            }
        }
    }
}
</style>
