import { mainClient } from '~/network';

const state = {
    givenEstate: null,
    estateId: null,
    currentStepIndex: 0,
    newValues: {},
    isTouched: false,

    getFormDataIsLoading: false,
    getFormDataError: null,
    getFormDataResult: null,

    createFormDataIsLoading: false,
    createFormDataError: null,
    createFormDataResult: null,

    updateFormDataIsLoading: false,
    updateFormDataError: null,
    updateFormDataResult: null,

    isAuthorized: false,

    getAuthUrlIsLoading: false,
    getAuthUrlError: null,
    getAuthUrlResult: null,
};

const getters = {
    steps(state) {
        if (!state.getFormDataResult) {
            return [];
        }
        return state.getFormDataResult.form.template.stages;
    },
    currentStep(state, getters) {
        if (!getters.steps.length) {
            return null;
        }
        return getters.steps[state.currentStepIndex];
    },
};

const actions = {
    async getFormData({ state }, { onSuccess = null, id = null } = {}) {
        if (state.getFormDataIsLoading || (!state.estateId && !id)) {
            return;
        }
        state.getFormDataIsLoading = true;
        const { data, error } = await mainClient.status.requests.getFormData({ id: id || state.estateId });
        if (error) {
            state.getFormDataError = error;
            state.getFormDataResult = null;
        } else if (data) {
            state.getFormDataResult = data;
            state.getFormDataError = null;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.getFormDataIsLoading = false;
    },
    async createFormData({ state }, { onSuccess = null, id = null } = {}) {
        if (state.createFormDataIsLoading || (!state.estateId && !id)) {
            return;
        }
        state.createFormDataIsLoading = true;
        const { data, error } = await mainClient.status.requests.createFormData({ id: id || state.estateId });
        if (error) {
            state.createFormDataError = error;
            state.createFormDataResult = null;
        } else if (data) {
            state.createFormDataResult = data;
            state.createFormDataError = null;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.createFormDataIsLoading = false;
    },
    async updateFormData({ state }, { onSuccess = null, ...payload }) {
        if (state.updateFormDataIsLoading) {
            return;
        }
        state.updateFormDataIsLoading = true;
        const { data, error } = await mainClient.status.requests.updateFormData(payload);
        if (error) {
            state.updateFormDataError = error;
            state.updateFormDataResult = null;
        } else if (data) {
            state.updateFormDataResult = data;
            state.updateFormDataError = null;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.updateFormDataIsLoading = false;
    },
    // Debug request version
    // async getAuthUrl({ state }, { onSuccess = null, id = null } = {}) {
    //     if (state.getAuthUrlIsLoading) {
    //         return;
    //     }
    //     state.getAuthUrlIsLoading = true;
    //     setTimeout(() => {
    //         const data = { url: 'https://www.google.com/' };
    //         state.getAuthUrlResult = data;
    //         state.getAuthUrlError = null;
    //         if (onSuccess) {
    //             onSuccess(data);
    //         }
    //         state.getAuthUrlIsLoading = false;
    //     }, 3000);
    // },
    async getAuthUrl({ state }, { onSuccess = null, id = null } = {}) {
        if (state.getAuthUrlIsLoading) {
            return;
        }
        state.getAuthUrlIsLoading = true;
        const { data, error } = await mainClient.status.requests.getAuthUrl();
        if (error) {
            state.getAuthUrlError = error;
            state.getAuthUrlResult = null;
        } else if (data) {
            state.getAuthUrlResult = data;
            state.getAuthUrlError = null;
            if (onSuccess) {
                onSuccess(data);
            }
        }
        state.getAuthUrlIsLoading = false;
    },
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
