// import moment from 'moment';
import { date as dateTransform } from '~/network/mainClient/common/transforms/responses';
import { userProfile as userProfileTransform } from '~/network/mainClient/users/transforms/responses';
import config from '~/config';
import utils from '~/utils';

export const investmentType = (data) => {
    return {
        id: data.id || -1,
        code: data.name || 'unknown',
        title: config.common.investmentTypes.find(x => x.code === data.name)?.title || 'Неизвестный тип',
    };
};

export const item = (data) => {
    const result = {
        id: data.id,
        title: data.name || 'Название объекта',
        description: data.description || 'У этого объекта еще нет описания',
        type: type(data.type),
        document: data.document
            ? { title: data.document_title || 'Презентация по объекту', url: data.document }
            : null,
        image: data.image || 'https://placeimg.com/640/640/arch',
        video: data.youtube_video || null,
        address: data.address || 'Адрес не указан',
        targetProfitability: data.finance ? parseFloat(data.finance.target_profitability || 0) : 0,
        currentProfitability: data.finance ? parseFloat(data.finance.current_profitability || 0) : 0,
        targetDividendProfitability: data.finance ? parseFloat(data.finance.target_dividend_profitability || 0) : 0,
        currentDividendProfitability: data.finance ? parseFloat(data.finance.current_dividend_profitability || 0) : 0,
        sharePrice: parseFloat(data.share_price) || 0.01,
        sharePriceFormatted: utils.formats.formatNumberWithSpaces(999, true),
        yearPercent: data.year_percent ? parseFloat(data.year_percent) : 0,
        minimumInvestment: data.finance ? parseFloat(data.finance.minimum_investment_amount) : 0,
        totalCost: data.finance ? parseFloat(data.finance.total_cost) : 0,
        url: data.url || (data.id > -1 ? `${config.urls.estates.list}${data.id}` : '#'),
        balance: parseFloat(data.balance) || 0,
        target: data.finance ? data.finance.target : 0,
        capRate: data.finance ? parseFloat(data.finance.cap_rate) : 0,
        gba: data.gba || 0,
        gla: data.gla || 0,
        occupancy: data.occupancy || 0,
        currency: 'руб.',
        investments: 0,
        dividends: 0,
        income: 0,
        irr: 0,
        mm: 0,
        share: 0,
        status: data.status || 'new',
        state: 'collecting',
        collectingProgress: 0,
        availableInvestmentTypes: data.investment_types.map(investmentType),
        isLoanInvestmentTypeAvailable: data.investment_types.some(x => x.name === 'loan'),
        quarterlyReports: data.quarterly_reports || [],
        targetThresholdMultiplier: parseInt(data.overage_limit || 0, 10) / 100 + 1,
    };
    const isFullByStatus = [
        'collected',
        'wait_sign',
        'signed',
        'loan_issued',
        'loan_converted',
        'completed',
    ].indexOf(result.status) > -1;

    if (result.status === 'sold') {
        result.state = 'sold';
    } else if (result.status === 'completed') {
        result.state = 'completed';
    } else if (isFullByStatus || (result.balance >= result.target * result.targetThresholdMultiplier)) {
        result.state = 'collected';
    }
    const getHumanizedSum = (sum, onlyNumber = false, referenceSum = null, withCurrency = true) => {
        const sumToUse = referenceSum || sum;
        let result = '';
        if (sumToUse < 1000) {
            return sum !== 0 ? sum + ' ₽' : sum;
        } else if (sumToUse < 1000000) {
            result = Math.floor(sum / 1000).toFixed(0) + (onlyNumber ? '' : ' тыс');
        } else if (sumToUse < 1000000000) {
            result = Math.floor(sum / 1000000).toFixed(0) + (onlyNumber ? '' : ' млн');
        } else {
            result = Math.floor(sum / 1000000000).toFixed(0) + (onlyNumber ? '' : ' млрд');
        }
        return withCurrency ? result + ' ₽' : result;
    };
    const getBalanceStateText = campaign => {
        if (!campaign.isActive && !campaign.isCompleted) {
            return 'Не начато';
        }
        if (result.status !== 'collecting' || campaign.isCompleted || campaign.balance >= campaign.target) {
            return getHumanizedSum(campaign.target);
        }
        const isBothUnitsNeeded = Math.ceil(campaign.target.toString().length / 3) !== Math.ceil(campaign.balance.toString().length / 3);
        return getHumanizedSum(campaign.balance, !isBothUnitsNeeded, isBothUnitsNeeded ? null : campaign.target, false) + ' / ' + getHumanizedSum(campaign.target);
    };
    let isActiveCampaignFound = false;
    let cumulativeTarget = 0;
    result.investmentCampaigns = data.investment_rounds ? data.investment_rounds.map((campaign, i) => {
        if (campaign.is_active) {
            isActiveCampaignFound = true;
        }
        const enrichedCampaign = {
            index: i,
            target: campaign.target,
            isActive: campaign.is_active,
            isCompleted: !isActiveCampaignFound,
            balance: Math.max(result.balance - cumulativeTarget, 0),
        };
        enrichedCampaign.balanceStateText = getBalanceStateText(enrichedCampaign);
        cumulativeTarget += campaign.target;
        return enrichedCampaign;
    }) : null;

    if (
        result.status === 'completed' &&
        result.investmentCampaigns.findIndex(x => x.isActive) > -1 &&
        (result.investmentCampaigns.findIndex(x => x.isActive) + 1) < result.investmentCampaigns.length
    ) {
        result.state = 'round-completed';
    }

    result.collectingProgress = result.target === 0 ? 1 : Math.max(Math.min(result.balance / result.target, 1), 0);

    return result;
};

export const list = (data) => {
    const result = {
        items: data.results.map(item),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
        ...data,
    };
    return result;
};

export const type = (data) => {
    if (!data) {
        return {
            id: -1,
            title: 'Особый',
            isActive: false,
            icon: 'estate-type-street-retail',
        };
    }
    const typeOfData = typeof data;
    if (typeOfData === 'string' || typeOfData === 'number') {
        return {
            id: data,
            title: 'Особый',
            isActive: false,
            icon: 'estate-type-street-retail',
        };
    }
    return {
        id: data.id || -1,
        title: data.name || 'Особый',
        isActive: data.is_active || false,
        icon: data.icon ? 'estate-type-' + data.icon : 'estate-type-street-retail',
    };
};

export const typesList = (data) => {
    const result = {
        items: data.results.map(type),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
        ...data,
    };
    return result;
};

export const chartData = (data) => {
    const sortedYears = Object.keys(data).sort((a, b) => a - b);
    const result = sortedYears.reduce((acc, year, yearIndex) => {
        const quarterOffset = yearIndex === 0 ? 4 - data[year].length : 0;
        data[year].forEach((quarterValue, quarterIndex) => {
            acc.push({
                category: `${quarterIndex + 1 + quarterOffset} кв.\n${year}`,
                value: quarterValue,
            });
        });
        return acc;
    }, []);
    return result;
};

// admin estate info field
export const info = (data) => {
    if (data === null) {
        return {
            id: -1,
            maxLoanTerm: null,
            shareCapital: '',
        };
    }
    return {
        id: data.id,
        maxLoanTerm: dateTransform(data.max_loan_term),
        shareCapital: parseFloat(data.share_capital),
    };
};

export const adminEstateItemDocument = (data) => {
    return {
        id: data.id,
        title: data.title,
        url: data.file,
        slug: data.slug,
    };
};

export const adminEstateItem = (data) => {
    const result = {
        // commented fields below are filled from ...item()-transform
        ...item(data),
        // id: data.id || -1,
        // balance: data.balance || 0,
        balanceSigned: data.signed_balance || 0,
        // status: data.status || 'new',
        // url: data.url || '#',

        // general
        // title: data.name || 'Название объекта',
        // type: type(data.type),
        // address: data.address || '',
        description: data.description || '',
        totalValue: data.total_cost || 0,
        currentDividendProfitability: data.current_dividend_profitability || 0,
        target: data.target || 0,
        targetDividendProfitability: data.target_profitability || 0,
        // yearPercent: data.year_percent ? parseFloat(data.year_percent) : 0,
        capRate: data.cap_rate || 0,
        totalSquare: data.gba || 0,
        rentableSquare: data.gla || 0,
        occupancy: data.occupancy || 0,
        minimalInvestmentAmount: data.minimum_investment_amount || 0,
        annualIndexing: data.annual_indexing || 0,
        info: info(data.info),
        // image
        // image: data.image || null,
        // document
        documentTitle: data.document_title || '',
        document: data.document || null,
        // activity
        isActive: !!data.is_active,

        // legal
        // general
        orgTitle: data.juridical_info.organization_name || '',
        orgAddress: data.juridical_info.organization_address || '',
        orgJurAddress: data.juridical_info.legal_address || '',
        orgMailAddress: data.juridical_info.mailing_address || '',
        ogrn: data.juridical_info.ogrn || '',
        okved: data.juridical_info.okved || '',
        inn: data.juridical_info.inn || '',
        registrationDate: dateTransform(data.juridical_info.date_registration) || '',
        // banking data
        bankTitle: data.juridical_info.bank_name || '',
        kpp: data.juridical_info.kpp || '',
        bik: data.juridical_info.bik || '',
        bankAccount: data.juridical_info.bank_account || '',
        cadastralAccount: data.juridical_info.cadastral_account || '',

        // files
        templates: data.templates?.map(x => adminEstateItemDocument(x)) || [],
        dsur: data.dsur_document ? { ...adminEstateItemDocument(data.dsur_document), title: 'ДСУР' } : null,
    };
    return result;
};

export const adminEstateRoundsList = ({ results, ...data }) => {
    let isActiveCampaignFound = false;
    return results.map((campaign, i) => {
        if (campaign.is_active) {
            isActiveCampaignFound = true;
        }
        const enrichedCampaign = {
            index: i,
            target: campaign.target,
            isActive: campaign.is_active,
            isCompleted: !isActiveCampaignFound,
            balance: campaign.balance || 0,
            balanceSigned: campaign.signed_balance || 0,
            displayMode: (campaign.status === 'new' || campaign.status === 'collected') ? 'collecting' : 'signing',
            balanceToShow: (campaign.status === 'new' || campaign.status === 'collected') ? campaign.balance : (campaign.signed_balance || 0),
            investorsCount: campaign.investors,
            status: campaign.status || 'not-started',
            requests: campaign.investment_requests.map(x => adminEstateRequestsListItem(x)),
            investmentAgreementCount: campaign.investment_agreements_count,
            investmentProposalCount: campaign.investment_proposal_count,
            transferOrderCount: campaign.transfer_order_count,
            investmentAcceptCount: campaign.investment_accept_count,
        };
        return enrichedCampaign;
    });
};

export const adminEstateRequestsListItem = (data) => {
    const amount = data.amount ? parseFloat(data.amount) : 0;
    const result = {
        id: data.id || -1,
        created: dateTransform(data.created) || new Date(),
        amount,
        amountFormatted: utils.formats.formatNumberWithSpaces(amount, true),
        applicant: data.user ? { ...userProfileTransform(data.user), id: data.user.id } : null,
        account: data.user ? data.user.account_code : 'Неизвестно',
        status: data.status || 'new',
        reservedBalance: parseInt(data.reserved_balance?.amount, 10),
        round: data.investment_round || 0,
        transferOrder: data.transfer_order || null,
        investmentAgreement: data.investment_agreement || null,
        investmentProposal: data.investment_proposal || null,
        investmentAccept: data.investment_accept || null,
        isVip: data.is_vip || false,
    };
    return result;
};

export const adminEstateRequestsList = ({ results, count, page, ...data }) => {
    const result = {
        ...data,
        items: results.map(adminEstateRequestsListItem),
        paging: {
            count: count || 0,
            page: page || 1,
        },
    };
    return result;
};

export const adminEstateSharesListItem = (data) => {
    return {
        date: dateTransform(data.modified),
        name: [
            data.user.last_name,
            data.user.first_name,
            data.user.second_name,
        ].filter(x => !!x).join(' '),
        quantity: data.quantity,
        account: '???',
        file: '#???',
    };
};

export const adminEstateSharesList = (data) => {
    const result = {
        items: data.results.map(adminEstateSharesListItem),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
    };
    return result;
};
